import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { getTransferInOrder, getTransferOutOrder } from 'Redux/actions';
import Top from 'Components/top';
import Tab from 'Components/tab';
import loc from 'Components/languages';
import LoadingOverlay from 'Components/loading-overlay';
import Pagination from 'Components/pagination';
import TransferOrderListItem from './components/TransferOrderListItem.jsx';
import styles from './PageStockTransfer.less';

class PageStockTransfer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			currentTab: this.getPage(props) === 'in' ? 'transferIn' : 'transferOut',
			page: 1,
		};
		this.tabs = [
			'transferIn',
			'transferOut',
		];
		this.getPage = this.getPage.bind(this);
		this.fetchData = this.fetchData.bind(this);
		this.onChangeTabHandler = this.onChangeTabHandler.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	getPage(props) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.page;
	}

	fetchData() {
		const { currentTab, page } = this.state;
		const param = {
			payload: {
				page,
				size: 100,
				sort_column: 'st_date',
				sort_direction: 'desc',
			},
		};
		if (currentTab === 'transferOut') {
			this.props.getTransferOutOrder(param);
		} else {
			this.props.getTransferInOrder(param);
		}
	}

	onChangeTabHandler(tab) {
		this.setState({
			currentTab: tab,
			page: 1,
		}, this.fetchData);
		this.props.history.push(`/app/stock-transfer/${tab === 'transferIn' ? 'in' : 'out'}`);
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	render() {
		const { transferInOrderInfo, transferOutOrderInfo } = this.props;
		const { currentTab } = this.state;
		const info = currentTab === 'transferOut' ? transferOutOrderInfo : transferInOrderInfo;
		const isLoading = info.isFetching;
		return (
			<LoadingOverlay active={ isLoading }>
				<div className="uk-flex uk-flex-column">
					<Top name="stockTransfer" />

					<div>
						<Tab
							tabs={ this.tabs }
							current={ currentTab }
							onChange={ this.onChangeTabHandler }
						/>
					</div>

					<div
						className={ classnames('uk-height-large', 'uk-overflow-auto', styles.list) }
						data-uk-height-viewport="offset-top: true; offset-bottom: 87px"
					>
						<ul className="uk-list uk-list-divider">
							{
								info.data && info.data.data && info.data.data.map((order, index) => (
									<TransferOrderListItem
										key={ `page-stock-transfer-list-item-${index}` }
										index={ index }
										order={ order }
										type={ currentTab }
									/>
								))
							}
						</ul>
					</div>

					<Pagination
						data={ info.data }
						onPageChange={ this.onChangePageHandler }
					/>

					{
						currentTab === 'transferOut' && (
							<div className="uk-position-bottom-right">
								<Link
									to="/app/stock-transfer/out/create"
									className="uk-button uk-padding-large uk-padding-remove-vertical uk-position-relative uk-button-primary"
								>
									{ loc.createTransferOut }
								</Link>
							</div>
						)
					}
				</div>
			</LoadingOverlay>
		);
	}
}

export default withRouter(connect(
	(state) => ({
		transferInOrderInfo: state.transferInOrderInfo,
		transferOutOrderInfo: state.transferOutOrderInfo,
	}),
	(dispatch) => ({
		getTransferInOrder: para => dispatch(getTransferInOrder(para)),
		getTransferOutOrder: para => dispatch(getTransferOutOrder(para)),
	})
)(PageStockTransfer));