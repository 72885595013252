import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './BrandListItem.less';

class BrandListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { onSelect, brand } = this.props;
		onSelect(brand);
	}

	render() {
		const { brand } = this.props;
		return (
			<li className={ styles.container } onClick={ this.onClickHandler }>
				<div className="uk-text-primary uk-text-bold">
					{ brand }
				</div>
			</li>
		);
	}
}

BrandListItem.propTypes = {
	brand: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default BrandListItem;