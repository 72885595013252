import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from 'Components/modal';
import Spinner from 'Components/spinner';
import loc from 'Components/languages';
import BrandListItem from './BrandListItem.jsx';
import { getPmsFilterOptions } from 'Redux/actions';
import styles from './ModalSelectBrand.less';

class ModalSelectBrand extends PureComponent {
	constructor() {
		super();
		this.state = {
			keyword: '',
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				top: '10px',
				bottom: '10px',
				maxHeight: '600px',
				padding: 0,
				overflow: 'visible',
			},
		};
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
	}

	componentDidMount() {
		const { keyword } = this.props;
		this.setState({
			keyword,
		});
	}

	componentDidUpdate(prevProps) {
		const isOpen = this.props.isOpen;
		const prevIsOpen = prevProps.isOpen;
		if (isOpen !== prevIsOpen) {
			if (isOpen) {
				this.props.getPmsFilterOptions({
					payload: 'brand',
				});
				this.setState({
					keyword: this.props.keyword,
				});
			}
		}
	}

	sortOption(a, b) {
		return a.localeCompare(b);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		});
	}

	render() {
		const { isOpen, onToggle, onSelect, pmsFilterOptionsInfo } = this.props;
		const { keyword } = this.state;
		const keywordUpperCase = keyword && keyword.toUpperCase();
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.selectBrand }
				style={ this.modalStyle }
			>
				<div className={ styles.desc }>
					{ loc.addItemsByBrand }
				</div>
				<div className={ styles.searchContainer }>
					<div className={ styles.search }>
						<input
							type="text"
							value={ keyword }
							placeholder={ loc.typeAndSearch }
							className={ styles.input }
							autoFocus
							onChange={ this.onChangeKeywordHandler }
						/>
						<div className={ styles.icon }>
							<FontAwesomeIcon icon={ faSearch } />
						</div>
					</div>
				</div>
				<div className={ styles.list }>
					<ul className="uk-list uk-list-divider">
						{
							
							pmsFilterOptionsInfo.isFetching ? (
								<div className="uk-margin-top uk-margin-bottom">
									<Spinner />
								</div>
							) : (
								pmsFilterOptionsInfo.data
									.sort(this.sortOption)
									.map((brand, index) => {
										if (!keywordUpperCase || keywordUpperCase && brand.includes(keywordUpperCase)) {
											return (
												<BrandListItem
													key={ `brand-list-item-${index}` }
													brand={ brand }
													onSelect={ onSelect }
												/>
											);
										}
									})
							)
						}
					</ul>
				</div>
			</Modal>
		);
	}
}

ModalSelectBrand.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		pmsFilterOptionsInfo: state.pmsFilterOptionsInfo,
	}),
	(dispatch) => ({
		getPmsFilterOptions: para => dispatch(getPmsFilterOptions(para)),
	})
)(ModalSelectBrand);