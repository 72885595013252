import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import { List } from 'immutable';
import Modal from 'Components/modal';
import Button from 'Components/button';
import Tab from 'Components/tab';
import loc from 'Components/languages';
import AppliedFilterTag from './AppliedFilterTag.jsx';
import { getWarehouse, getPmsFilterOptions, getPmsFilters } from 'Redux/actions';
import styles from './ModalAdvancedSearch.less';

class ModalAdvancedSearch extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			sortBy: 'itemName',
			orderBy: 'ascending',
			shop: props.defaultShowAllShops ? 'allShop' : 'currentShop',
			warehouses: [],
			retailPriceFrom: '',
			retailPriceTo: '',
			pmsFilters: new List(),

			pmsFilter: null,
			pmsFilterOption: null,
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 350px)',
				right: 'calc(50% - 350px)',
				top: '10px',
				bottom: '10px',
				padding: 0,
				overflow: 'visible',
			},
		};
		this.tabs = {
			sortBy: [
				'itemName',
				'itemCode',
				'retailPrice',
			],
			orderBy: [
				'ascending',
				'descending',
			],
			shop: [
				'currentShop',
				'specificShops',
				'allShop',
			],
		};
		this.sortOption = this.sortOption.bind(this);
		this.getWarehouses = this.getWarehouses.bind(this);
		this.getPmsFilters = this.getPmsFilters.bind(this);
		this.getPmsFilterOptions = this.getPmsFilterOptions.bind(this);
		this.onChangeSortByHandler = this.onChangeHandler.bind(this, 'sortBy');
		this.onChangeOrderByHandler = this.onChangeHandler.bind(this, 'orderBy');
		this.onChangeShopHandler = this.onChangeHandler.bind(this, 'shop');
		this.onChangeWarehousesHandler = this.onChangeWarehousesHandler.bind(this);
		this.onChangeRetailPriceFromHandler = this.onChangeRetailPriceHandler.bind(this, 'retailPriceFrom');
		this.onChangeRetailPriceToHandler = this.onChangeRetailPriceHandler.bind(this, 'retailPriceTo');
		this.onChangePmsFilterHandler = this.onChangePmsFilterHandler.bind(this);
		this.onChangePmsFilterOptionHandler = this.onChangePmsFilterOptionHandler.bind(this);
		this.onAddPmsFilterHandler = this.onAddFilterHandler.bind(this, 'pmsFilter');
		this.onRemoveAppliedFilterHandler = this.onRemoveAppliedFilterHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
	}

	componentDidMount() {
		const { showShopFilter } = this.props;
		if (showShopFilter) {
			this.props.getWarehouse({
				payload: {
					page: 1,
					size: -1,
					status: 'active',
				},
			});
		}
		this.props.getPmsFilters();
	}

	componentDidUpdate(prevProps, prevState) {
		const pmsFilter = this.state.pmsFilter;
		const prevPmsFilter = prevState.pmsFilter;
		const showShopFilter = this.props.showShopFilter;
		const prevShowShopFilter = prevProps.showShopFilter;

		if (pmsFilter && pmsFilter !== prevPmsFilter) {
			this.props.getPmsFilterOptions({
				payload: pmsFilter.value,
			});
		}

		if (showShopFilter && showShopFilter !== prevShowShopFilter) {
			this.props.getWarehouse({
				payload: {
					page: 1,
					size: -1,
					status: 'active',
				},
			});
		}
	}

	sortOption(a, b) {
		return a.label.localeCompare(b.label);
	}

	getWarehouses() {
		const { warehouseInfo } = this.props;
		if (!warehouseInfo.isFetching) {
			return warehouseInfo.data && warehouseInfo.data.data && warehouseInfo.data.data.map((warehouse) => ({
				label: warehouse.wh_desc,
				value: warehouse.wh_code,
			}));
		}
	}

	getPmsFilters() {
		const { appLanguage, pmsFilterInfo } = this.props;
		let nameField = 'name_en';
		switch (appLanguage.language) {
		case 'zh-Hant':
			nameField = 'name_tc';
			break;
		case 'zh-Hans':
			nameField = 'name_sc';
			break;
		}
		return pmsFilterInfo.data.map((pmsFilter) => ({
			label: pmsFilter[nameField],
			value: pmsFilter.key,
		}));
	}

	getPmsFilterOptions() {
		const { pmsFilterOptionsInfo } = this.props;
		return pmsFilterOptionsInfo.data.map((pmsFilterOption) => ({
			label: pmsFilterOption,
			value: pmsFilterOption,
		})).sort(this.sortOption);
	}

	onChangeHandler(key, value) {
		let newState = {};
		newState[key] = value;
		if (key === 'shop' && value !== 'specificShops') {
			newState.warehouses = [];
		}
		this.setState(newState);
	}

	onChangeWarehousesHandler(selectedOptions) {
		this.setState({
			warehouses: selectedOptions,
		});
	}

	onChangeRetailPriceHandler(key, e) {
		let newState = {};
		newState[key] = e.target.value;
		this.setState(newState);
	}

	onChangePmsFilterHandler(selectedOption) {
		this.setState({
			pmsFilter: selectedOption,
		});
	}

	onChangePmsFilterOptionHandler(value) {
		let newState = {
			pmsFilterOption: value,
		};
		this.setState(newState, this.onAddPmsFilterHandler);
	}

	onAddFilterHandler(type) {
		let newState = {};
		const filtersKey = `${type}s`;
		const filterKey = type;
		const filterOptionKey = `${type}Option`;

		newState[filterKey] = null;
		newState[filterOptionKey] = null;

		const filters = this.state[filtersKey];
		const filter = this.state[filterKey].value;
		const filterOption = this.state[filterOptionKey].value;
		const index = filters.findIndex((f) => filter === f.filter_key);
		if (index === -1) {
			newState[filtersKey] = filters.push({
				filter_key: filter,
				filter_options: [filterOption],
			});
		} else {
			const filterOptions = filters.get(index).filter_options;
			newState[filtersKey] = filters.set(
				index,
				{
					filter_key: filter,
					filter_options: [
						...filterOptions,
						filterOption
					],
				}
			);
		}

		this.setState(newState);
	}

	onRemoveAppliedFilterHandler(type, filterIndex, filterOptionIndex) {
		let newState = {};
		const filtersKey = `${type}s`;
		const filters = this.state[filtersKey];
		let filter = filters.get(filterIndex);

		filter = {
			filter_key: filter.filter_key,
			filter_options: [
				...filter.filter_options,
			],
		};
		filter.filter_options.splice(filterOptionIndex, 1);

		if (filter.filter_options.length > 0) {
			newState[filtersKey] = filters.set(filterIndex, filter);
		} else {
			newState[filtersKey] = filters.remove(filterIndex);
		}
		this.setState(newState);
	}

	onSubmitHandler(e) {
		const { onChange, appLanguage } = this.props;
		const { orderBy, sortBy, shop, warehouses, retailPriceFrom, retailPriceTo, pmsFilters } = this.state;
		e.preventDefault();
		let data = {
			sort_direction: orderBy === 'ascending' ? 'asc' : 'desc',
			is_current_shop: shop === 'currentShop',
			price_from: retailPriceFrom,
			price_to: retailPriceTo,
			pmsFilters: pmsFilters.toJS(),
		};
		if (shop === 'specificShops') {
			data.shops = warehouses.map((warehouse) => warehouse.value);
		} else {
			data.shops = [];
		}
		switch (sortBy) {
		case 'itemName':
			data.sort_column = appLanguage.language === 'en' ? 'item_name_en' : 'item_name_tc';
			break;
		case 'itemCode':
			data.sort_column = 'item_code';
			break;
		case 'retailPrice':
			data.sort_column = 'price_sales';
			break;
		}
		onChange(data);
	}

	render() {
		const { isOpen, onToggle, showShopFilter, warehouseInfo, pmsFilterInfo, pmsFilterOptionsInfo } = this.props;
		const { sortBy, orderBy, shop, warehouses, retailPriceFrom, retailPriceTo, pmsFilters, pmsFilter, pmsFilterOption } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				title={ loc.advancedSearch }
				style={ this.modalStyle }
			>
				<form onSubmit={ this.onSubmitHandler } className={ styles.form }>
					<div className={ styles.filters }>
						<div className={ styles.title }>
							{ loc.sorting }
						</div>

						<div className={ classnames('uk-flex', styles.row) }>
							<div className={ classnames('uk-width-2-5', 'uk-text-primary', styles.label) }>
								{ loc.sortBy }
							</div>
							<div className={ classnames('uk-width-3-5', styles.value) }>
								<Tab
									tabs={ this.tabs.sortBy }
									current={ sortBy }
									onChange={ this.onChangeSortByHandler }
									buttonClassName={ styles.tabButton }
								/>
							</div>
						</div>

						<div className={ classnames('uk-flex', styles.row) }>
							<div className={ classnames('uk-width-2-5', 'uk-text-primary', styles.label) }>
								{ loc.orderBy }
							</div>
							<div className={ classnames('uk-width-3-5', styles.value) }>
								<Tab
									tabs={ this.tabs.orderBy }
									current={ orderBy }
									onChange={ this.onChangeOrderByHandler }
									buttonClassName={ styles.tabButton }
								/>
							</div>
						</div>

						{
							showShopFilter && (
								<Fragment>
									<div className={ classnames('uk-flex', styles.row, styles.row2x) }>
										<div className={ classnames('uk-width-2-5', 'uk-text-primary', styles.label) }>
											{ loc.shop }
										</div>
										<div className={ classnames('uk-width-3-5', styles.value) }>
											<Tab
												tabs={ this.tabs.shop }
												current={ shop }
												onChange={ this.onChangeShopHandler }
												className={ styles.tab }
												buttonClassName={ styles.tabButton }
											/>

											<Select
												isLoading={ warehouseInfo.isFetching }
												isDisabled={ warehouseInfo.isFetching || shop !== 'specificShops' }
												options={ this.getWarehouses() }
												isMulti={ true }
												value={ warehouses }
												placeholder={ `${loc.select}...` }
												onChange={ this.onChangeWarehousesHandler }
											/>
										</div>
									</div>
								</Fragment>
							)
						}

						<div className={ classnames('uk-flex', styles.row) }>
							<div className={ classnames('uk-width-1-3', 'uk-text-primary', styles.label) }>
								{ loc.retailPriceFrom }
							</div>
							<div className={ classnames('uk-width-2-3', styles.value) }>
								<input
									id="retailPriceFrom"
									type="text"
									className={ classnames('uk-input', 'uk-width-1-1', 'uk-text-center') }
									value={ retailPriceFrom }
									onChange={ this.onChangeRetailPriceFromHandler }
								/>
							</div>
						</div>

						<div className={ classnames('uk-flex', styles.row) }>
							<div className={ classnames('uk-width-1-3', 'uk-text-primary', styles.label) }>
								{ loc.retailPriceTo }
							</div>
							<div className={ classnames('uk-width-2-3', styles.value) }>
								<input
									id="retailPriceTo"
									type="text"
									className={ classnames('uk-input', 'uk-width-1-1', 'uk-text-center') }
									value={ retailPriceTo }
									onChange={ this.onChangeRetailPriceToHandler }
								/>
							</div>
						</div>

						<div className={ styles.title }>
							{ loc.appliedFilters }
						</div>

						<div className={ styles.applied }>
							{
								pmsFilters.map((pmsFilter, i) => (
									pmsFilter.filter_options.map((filterOption, j) => (
										<AppliedFilterTag
											key={ `modal-advanced-search-applied-pms-filter-tag-${i}-${j}` }
											text={ filterOption }
											type="pmsFilter"
											filterIndex={ i }
											filterOptionIndex={ j }
											onRemove={ this.onRemoveAppliedFilterHandler }
										/>
									))
								))
							}
						</div>

						<div className={ styles.title }>
							{ loc.filtering }
						</div>

						<div className={ styles.options }>
							<Select
								isLoading={ pmsFilterInfo.isFetching }
								isDisabled={ pmsFilterInfo.isFetching }
								menuPlacement="top"
								options={ this.getPmsFilters() }
								value={ pmsFilter }
								placeholder={ `${loc.select}...` }
								onChange={ this.onChangePmsFilterHandler }
							/>
							<div className="uk-flex uk-margin-small-top">
								<div className="uk-width-1-1">
									<Select
										isLoading={ pmsFilterOptionsInfo.isFetching }
										isDisabled={ pmsFilterInfo.isFetching || pmsFilterOptionsInfo.isFetching || !pmsFilter }
										menuPlacement="top"
										options={ this.getPmsFilterOptions() }
										value={ pmsFilterOption }
										placeholder={ `${loc.select}...` }
										onChange={ this.onChangePmsFilterOptionHandler }
									/>
								</div>
							</div>
						</div>
					</div>

					<div className={ styles.button }>
						<Button
							text={ loc.updateResults }
							theme="primary"
							type="submit"
							onClick={ this.onSubmitHandler }
						/>
					</div>
				</form>
			</Modal>
		);
	}
}

ModalAdvancedSearch.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	showShopFilter: PropTypes.bool,
	defaultShowAllShops: PropTypes.bool,
};

export default connect(
	(state) => ({
		appLanguage: state.appLanguage,
		warehouseInfo: state.warehouseInfo,
		pmsFilterInfo: state.pmsFilterInfo,
		pmsFilterOptionsInfo: state.pmsFilterOptionsInfo,
	}),
	(dispatch) => ({
		getWarehouse: para => dispatch(getWarehouse(para)),
		getPmsFilters: para => dispatch(getPmsFilters(para)),
		getPmsFilterOptions: para => dispatch(getPmsFilterOptions(para)),
	})
)(ModalAdvancedSearch);