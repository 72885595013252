exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2K-ON {\n  margin-bottom: 6px;\n}\n._2K-ON ._2nc6i {\n  margin-bottom: 5px;\n  font-weight: bold;\n}\n._2K-ON ._3RvK_ {\n  padding-left: 20px;\n  line-height: 35px;\n}\n._2K-ON ._3RvK_.Dkley {\n  word-wrap: break-word;\n  white-space: pre-wrap;\n}\n._2oF_4 {\n  padding-bottom: 40px;\n  padding-left: 10px;\n}\n", ""]);

// Exports
exports.locals = {
	"row": "_2K-ON",
	"title": "_2nc6i",
	"content": "_3RvK_",
	"remark": "Dkley",
	"voidOrder": "_2oF_4"
};