import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styles from './WarehouseListItem.less';

class WarehouseListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { onSelect, warehouse } = this.props;
		onSelect(warehouse);
	}

	render() {
		const { warehouse } = this.props;
		return (
			<li className={ styles.container } onClick={ this.onClickHandler }>
				<div className="uk-text-primary uk-text-bold">
					{ warehouse.wh_desc }
				</div>
				<div className="uk-text-muted uk-text-small">
					<div>{ warehouse.wh_tel }</div>
					<div>{ warehouse.wh_add }</div>
				</div>
			</li>
		);
	}
}

WarehouseListItem.propTypes = {
	warehouse: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default WarehouseListItem;