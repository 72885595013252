import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classnames from 'classnames';
import Modal from 'Components/modal';
import Button from 'Components/button';
import loc from 'Components/languages';
import styles from './ModalQuantity.less';

class ModalQuantity extends PureComponent {
	constructor() {
		super();
		this.defaultState = {
			item_quantity: 1,
		};
		this.state = {
			...this.defaultState
		};
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '146px',
			},
		};
		this.onChangeQuantityHandler = this.onChangeQuantityHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onCancelHandler = this.onCancelHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const transactionItem = this.props.transactionItem;
		const prevTransactionItem = prevProps.transactionItem;
		if (transactionItem && transactionItem !== prevTransactionItem) {
			let newState = {
				...this.defaultState,
				item_quantity: transactionItem.get('item_quantity'),
			};
			this.setState(newState);
		}
	}

	onChangeQuantityHandler(e) {
		this.setState({
			item_quantity: parseInt(e.target.value) || 0,
		});
	}

	onSubmitHandler(e) {
		e.preventDefault();
		const { onChange, onToggle } = this.props;
		const { item_quantity } = this.state;
		let data = {
			item_quantity,
		};
		onChange(data);
		onToggle();
	}

	onCancelHandler() {
		const { onToggle } = this.props;
		this.setState(this.defaultState, onToggle);
	}

	render() {
		const { isOpen, onToggle } = this.props;
		const { item_quantity } = this.state;
		return (
			<Modal
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyle }
			>
				<form className="uk-form-stacked" onSubmit={ this.onSubmitHandler }>
					<div className="uk-margin">
						<label className={ classnames('uk-form-label', styles.label) }>{ loc.qty }</label>
						<div className="uk-form-controls">
							<input
								className={ classnames('uk-input', styles.inputQty) }
								type="text"
								placeholder={ loc.qty }
								value={ item_quantity }
								onChange={ this.onChangeQuantityHandler }
							/>
						</div>
					</div>

					<div className="uk-margin-small-top uk-text-center uk-flex">
						<div className={ classnames('uk-width-1-2', styles.buttonColumnLeft) }>
							<Button
								text={ loc.cancel }
								theme="primary"
								type="button"
								className="uk-width-1-1"
								onClick={ this.onCancelHandler }
							/>
						</div>
						<div className={ classnames('uk-width-1-2', styles.buttonColumnRight) }>
							<Button
								text={ loc.apply }
								theme="primary"
								type="submit"
								className="uk-width-1-1"
								onClick={ this.onSubmitHandler }
							/>
						</div>
					</div>
				</form>
			</Modal>
		);
	}
}

ModalQuantity.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	transactionItem: PropTypes.instanceOf(Map).isRequired,
};

export default ModalQuantity;