exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2BOND {\n  position: relative;\n  padding: 15px;\n}\n._2BOND._1rjZI {\n  border: 2px solid #b00 !important;\n}\n._2BOND._1rjZI {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n._15ZSn {\n  cursor: pointer;\n  padding-left: 20px;\n  padding-right: 20px;\n  margin-right: -20px;\n  margin-top: -5px;\n}\n.HxAZE {\n  cursor: pointer;\n  font-weight: bold;\n  text-decoration: underline;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "_2BOND",
	"invalid": "_1rjZI",
	"button": "_15ZSn",
	"link": "HxAZE"
};