import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import loc from 'Components/languages';
import Button from 'Components/button';
import Modal from 'Components/modal';
import styles from './ModalException.less';

class ModalException extends PureComponent {
	constructor() {
		super();
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '500px',
				padding: 0,
			},
		};
	}

	render() {
		const { isOpen, onToggle, transferOutOrderCreateInfo } = this.props;
		return (
			<Modal
				title="Error Occurred"
				isOpen={ isOpen }
				onToggle={ onToggle }
				style={ this.modalStyle }
			>
				<div className={ styles.container }>
					<ul className="uk-list uk-list-bullet">
						{
							transferOutOrderCreateInfo.data &&
							transferOutOrderCreateInfo.data.cart_exceptions &&
							transferOutOrderCreateInfo.data.cart_exceptions.map((cartException, index) => (
								<li key={ `modal-cart-exception-cart-${index}` }>
									<strong>{ cartException.errorCode }</strong>: { cartException.message }
								</li>
							))
						}
						{
							transferOutOrderCreateInfo.data &&
							transferOutOrderCreateInfo.data.salesman_exceptions &&
							transferOutOrderCreateInfo.data.salesman_exceptions.map((salesmanException, index) => (
								<li key={ `modal-cart-exception-salesman-${index}` }>
									<strong>{ salesmanException.errorCode }</strong>: { salesmanException.message }
								</li>
							))
						}
					</ul>
				</div>
				<div className={ styles.button }>
					<Button
						text={ loc.confirm }
						theme="primary"
						onClick={ onToggle }
					/>
				</div>
			</Modal>
		);
	}
}

ModalException.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
};

export default connect(
	state => ({
		transferOutOrderCreateInfo: state.transferOutOrderCreateInfo,
	})
)(ModalException);