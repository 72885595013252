import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { Map, List } from 'immutable';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Top from 'Components/top';
import LoadingOverlay from 'Components/loading-overlay';
import loc from 'Components/languages';
import Button from 'Components/button';
import Overlay from 'Components/overlay';
import InputText from 'Components/input-text';
import ModalRemark from 'Components/modal/remark';
import { compareJson } from 'Helpers/helpers';
import { getItem, getStock, createTransferOutOrder } from 'Redux/actions';
import * as cons from 'Redux/constants';
import ModalSalesman from '../sales-order/salesman/components/ModalSalesman.jsx';
import ModalSelectItem from '../sales-order/cart/components/ModalSelectItem.jsx';
import ModalWarehouse from './components/ModalWarehouse.jsx';
import ModalSelectBrand from './components/ModalSelectBrand.jsx';
import ModalException from './components/ModalException.jsx';
import TransactionItemRow from './components/TransactionItemRow.jsx';
import styles from './PageStockTransferOutOrder.less';

class PageStockTransferOutOrder extends PureComponent {
	constructor() {
		super();
		this.defaultOrder = {
			salesman_code: null,
			salesman_name: null,
			wh_code_to: null,
			wh_desc_to: null,
			wh_to: null,
			remark: null,
			transaction_items: new List(),
		};
		this.state = {
			order: new Map(this.defaultOrder),
			showModalWarehouse: true,
			showModalSalesman: false,
			showModalSelectItem: false,
			showModalRemark: false,
			showModalSelectBrand: false,
			showModalException: false,
			showOverlay: false,
			onHideOverlay: null,
			keyword: '',
			forceSelectLotNo: null,
		};
		this.keywordRef = React.createRef();
		this.isLoading = this.isLoading.bind(this);
		this.addTransactionItems = this.addTransactionItems.bind(this);
		this.onToggleModalWarehouseHandler = this.onToggleModalWarehouseHandler.bind(this);
		this.onToggleModalSalesmanHandler = this.onToggleModalSalesmanHandler.bind(this);
		this.onToggleModalSelectItemHandler = this.onToggleModalSelectItemHandler.bind(this);
		this.onToggleModalRemarkHandler = this.onToggleModalRemarkHandler.bind(this);
		this.onToggleModalSelectBrandHandler = this.onToggleModalSelectBrandHandler.bind(this);
		this.onToggleModalExceptionHandler = this.onToggleModalExceptionHandler.bind(this);
		this.onSelectWarehouseHandler = this.onSelectWarehouseHandler.bind(this);
		this.onSelectSalesmanHandler = this.onSelectSalesmanHandler.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onFocusRemarkHandler = this.onFocusRemarkHandler.bind(this);
		this.onChangeRemarkHandler = this.onChangeRemarkHandler.bind(this);
		this.onSearchHandler = this.onSearchHandler.bind(this);
		this.onShowOverlayHandler = this.onShowOverlayHandler.bind(this);
		this.onHideOverlayHandler = this.onHideOverlayHandler.bind(this);
		this.onSelectLotNoHandler = this.onSelectLotNoHandler.bind(this);
		this.onAddTransactionItemHandler = this.onAddTransactionItemHandler.bind(this);
		this.onAddItemsByBrandHandler = this.onAddItemsByBrandHandler.bind(this);
		this.onChangeTransactionItemHandler = this.onChangeTransactionItemHandler.bind(this);
		this.onRemoveTransactionItemHandler = this.onRemoveTransactionItemHandler.bind(this);
		this.onClickCheckOutHandler = this.onClickCheckOutHandler.bind(this);
		this.getTotalTransactionItemCount = this.getTotalTransactionItemCount.bind(this);
	}

	componentDidUpdate(prevProps) {
		const itemInfo = this.props.itemInfo;
		const prevItemInfo = prevProps.itemInfo;
		const stockInfo = this.props.stockInfo;
		const prevStockInfo = prevProps.stockInfo;
		const transferOutOrderCreateInfo = this.props.transferOutOrderCreateInfo;
		const prevTransferOutOrderCreateInfo = prevProps.transferOutOrderCreateInfo;
		if (!compareJson(itemInfo, prevItemInfo)) {
			if (itemInfo.type === cons.GET_ITEM.SUCCESS) {
				if (itemInfo.data.total === 1) {
					const newItem = itemInfo.data.data[0];
					this.onAddTransactionItemHandler(newItem);
					this.setState({
						keyword: '',
					});
					this.keywordRef.current && this.keywordRef.current.focus && this.keywordRef.current.focus();
				} else {
					this.setState({
						showModalSelectItem: true,
					});
				}
			}
		}


		if (!compareJson(stockInfo, prevStockInfo)) {
			if (stockInfo.type === cons.GET_STOCK.SUCCESS) {
				if (stockInfo.data.total >= 1) {
					if (stockInfo.data.total > stockInfo.data.data.length) {
						alert(`Only the first ${stockInfo.data.data.length} out of ${stockInfo.data.total} items can be added.`);
					}
					this.addTransactionItems(stockInfo.data.data);
					this.setState({
						keyword: '',
					});
					this.keywordRef.current && this.keywordRef.current.focus && this.keywordRef.current.focus();
				}
			}
		}

		if (!compareJson(transferOutOrderCreateInfo, prevTransferOutOrderCreateInfo)) {
			if (transferOutOrderCreateInfo.type === cons.CREATE_TRANSFER_OUT.SUCCESS) {
				if (transferOutOrderCreateInfo.data.salesman_exceptions.length || transferOutOrderCreateInfo.data.cart_exceptions.length) {
					this.setState({
						showModalException: true,
					});
				} else {
					const { order_type, transaction_number } = transferOutOrderCreateInfo.data;
					alert('Transfer order is created.');
					this.props.history.push(`/app/stock-transfer/out/${order_type}/${transaction_number}?print=1`);
				}
			}
		}
	}

	isLoading() {
		const { transferOutOrderCreateInfo, itemInfo, stockInfo } = this.props;
		return transferOutOrderCreateInfo.isFetching || itemInfo.isFetching || stockInfo.isFetching;
	}

	addTransactionItems(transactionItems) {
		let { order } = this.state;
		transactionItems.forEach((transactionItem) => {
			transactionItem.available_lot_nos && transactionItem.available_lot_nos.forEach((lot_no) => {
				let transactionItemMap = new Map(transactionItem);
				transactionItemMap = transactionItemMap.merge({
					item_quantity: transactionItem.default_qty,
					discount_percentage: 0,
					discount_reduction: 0,
					lot_no,
				});
				const index = order.get('transaction_items').findIndex(((item) => {
					return (transactionItemMap.get('lot_no') === item.get('lot_no') && transactionItemMap.get('item_code') === item.get('item_code'));
				}));
				order = order.set(
					'transaction_items',
					index === -1 ? (
						order.get('transaction_items').push(transactionItemMap)
					) : (
						order
							.get('transaction_items')
							.set(
								index,
								transactionItemMap.set(
									'item_quantity',
									transactionItemMap.get('item_quantity') + order.get('transaction_items').get(index).get('item_quantity')
								)
							)
					)
				);
			});
		});
		this.setState({
			order,
		});
	}

	onToggleModalWarehouseHandler(e) {
		e && e.target.blur();
		const { showModalWarehouse } = this.state;
		this.setState({
			showModalWarehouse: !showModalWarehouse,
		});
	}

	onToggleModalSalesmanHandler(e) {
		e && e.target.blur();
		const { showModalSalesman } = this.state;
		this.setState({
			showModalSalesman: !showModalSalesman,
		});
	}

	onToggleModalSelectItemHandler() {
		const { showModalSelectItem } = this.state;
		this.setState({
			showModalSelectItem: !showModalSelectItem,
		});
	}

	onToggleModalRemarkHandler() {
		const { showModalRemark } = this.state;
		this.setState({
			showModalRemark: !showModalRemark,
		});
	}

	onToggleModalSelectBrandHandler() {
		const { showModalSelectBrand } = this.state;
		this.setState({
			showModalSelectBrand: !showModalSelectBrand,
		});
	}

	onToggleModalExceptionHandler() {
		const { showModalException } = this.state;
		this.setState({
			showModalException: !showModalException,
		});
	}

	onSelectWarehouseHandler(warehouse) {
		const { order } = this.state;
		this.setState({
			order: order
				.set('wh_code_to', warehouse ? warehouse.wh_code : null)
				.set('wh_desc_to', warehouse ? warehouse.wh_desc : null)
				.set('wh_to', warehouse)
			,
			showModalWarehouse: false,
		});
	}

	onSelectSalesmanHandler(salesman) {
		const { order } = this.state;
		this.setState({
			order: order
				.set('salesman_code', salesman ? salesman.user_id : null)
				.set('salesman_name', salesman ? salesman.user_name : null)
			,
			showModalSalesman: false,
		});
	}

	onChangeKeywordHandler(e) {
		this.setState({
			keyword: e.target.value,
		});
	}

	onFocusRemarkHandler(e) {
		e.target.blur();
		const { showModalRemark } = this.state;
		this.setState({
			showModalRemark: !showModalRemark,
		});
	}

	onChangeRemarkHandler(remark) {
		const { order } = this.state;
		this.setState({
			order: order.set('remark', remark),
		});
	}

	onSearchHandler(e) {
		e.preventDefault();
		const { keyword } = this.state;
		const param = {
			payload: {
				page: 1,
				keyword,
				is_active: true,
				is_stock_check: true,
				is_current_shop: true,
				is_stock_transfer: true,
			},
		};
		this.props.getItem(param);
	}

	onShowOverlayHandler(callback) {
		this.setState({
			showOverlay: true,
			onHideOverlay: callback
		});
	}

	onHideOverlayHandler() {
		const { onHideOverlay } = this.state;
		this.setState({
			showOverlay: false,
			onHideOverlay: null,
		}, onHideOverlay);
	}

	onSelectLotNoHandler(index, lotNo) {
		let { order } = this.state;
		const transactionItem = order.get('transaction_items').get(index);
		const indexWithSameLotNo = order.get('transaction_items').findIndex(((item) => {
			return (lotNo.lot_no === item.get('lot_no') && transactionItem.get('item_code') === item.get('item_code'));
		}));
		if (indexWithSameLotNo === -1) {
			order = order.set(
				'transaction_items',
				order.get('transaction_items').set(
					index,
					transactionItem.set('lot_no', lotNo.lot_no)
				)
			);
		} else {
			order = order.set(
				'transaction_items',
				order
					.get('transaction_items')
					.set(
						indexWithSameLotNo,
						order
							.get('transaction_items')
							.get(indexWithSameLotNo)
							.set(
								'item_quantity',
								order
									.get('transaction_items')
									.get(indexWithSameLotNo)
									.get('item_quantity')
									+ transactionItem.get('item_quantity')
							)
					)
					.remove(index)
			);
		}

		this.setState({
			order,
			forceSelectLotNo: null,
		}, this.saveSalesOrder);
	}

	onAddTransactionItemHandler(transactionItem) {
		const { order } = this.state;
		transactionItem = new Map(transactionItem);
		transactionItem = transactionItem.merge({
			item_quantity: transactionItem.get('default_qty'),
			discount_percentage: 0,
			discount_reduction: 0,
		});
		const index = order.get('transaction_items').findIndex(((item) => {
			return (transactionItem.get('lot_no') === item.get('lot_no') && transactionItem.get('item_code') === item.get('item_code'));
		}));
		this.setState({
			order: order
				.set(
					'transaction_items',
					index === -1 ? (
						order.get('transaction_items').push(transactionItem)
					) : (
						order
							.get('transaction_items')
							.set(
								index,
								transactionItem.set(
									'item_quantity',
									transactionItem.get('item_quantity') + order.get('transaction_items').get(index).get('item_quantity')
								)
							)
					)
				)
			,
			// Force selecting lot no.
			forceSelectLotNo: !transactionItem.get('lot_no') && transactionItem.get('item_code'),
			showModalSelectItem: false,
		});
	}

	onAddItemsByBrandHandler(brand) {
		let param = {
			payload: {
				page: 1,
				size: 1000,
				is_active: true,
				is_current_shop: true,
				is_stock_check: true,
				include_lot_nos: true,
				sort_column: 'item_name_en',
				sort_direction: 'asc',
				pmsFilters: [{
					filter_key: 'brand',
					filter_options: [brand]
				}],
			},
		};
		this.props.getStock(param);
		this.setState({
			showModalSelectBrand: false,
		});
	}

	onChangeTransactionItemHandler(index, data) {
		const { order } = this.state;
		const transactionItem = order.get('transaction_items').get(index);
		this.setState({
			order: order
				.set(
					'transaction_items',
					order.get('transaction_items').set(
						index,
						transactionItem
							.set('item_quantity', data.item_quantity)
							.set('discount_percentage', data.discount_percentage)
							.set('discount_reduction', data.discount_reduction)
							.set('discount_amount', data.discount_amount)
					)
				)
			,
		}, this.saveSalesOrder);
	}

	onRemoveTransactionItemHandler(index) {
		const { order } = this.state;
		this.setState({
			order: order
				.set(
					'transaction_items',
					order.get('transaction_items').delete(index)
				)
			,
		}, this.saveSalesOrder);
	}

	onClickCheckOutHandler() {
		if (!confirm(loc.confirmToCheckOut)) {
			return;
		}

		const { order } = this.state;
		const param = {
			payload: {
				salesman_code: order.get('salesman_code'),
				wh_code_to: order.get('wh_code_to'),
				transaction_items: order.get('transaction_items'),
				remark: order.get('remark'),
			},
		};
		this.props.createTransferOutOrder(param);
	}

	getTotalTransactionItemCount() {
		const { order } = this.state;
		return order.get('transaction_items').reduce((total, transactionItem) => {
			return total + transactionItem.get('item_quantity');
		}, 0);
	}

	render() {
		const { order, showModalWarehouse, showModalSalesman, showModalSelectItem, showModalRemark, showModalSelectBrand, showModalException, showOverlay, keyword, forceSelectLotNo } = this.state;
		const { itemInfo, transferOutOrderCreateInfo } = this.props;
		const isLoading = this.isLoading();
		const exceptionMap = {};
		transferOutOrderCreateInfo.data.cart_exceptions && transferOutOrderCreateInfo.data.cart_exceptions.forEach((exception) => {
			if (exception.detail) {
				if (!exceptionMap[exception.detail]) {
					exceptionMap[exception.detail] = [];
				}
				exceptionMap[exception.detail].push(exception);
			}
		});
		return (
			<Fragment>
				<LoadingOverlay active={ isLoading }>
					<div className="uk-flex uk-flex-column">
						<Top name="stockTransfer" />

						<div>
							<div className="uk-margin-left uk-margin-remove-vertical uk-h3 uk-text-primary uk-text-uppercase">
								{ loc.transferOut }
							</div>
						</div>
						<div
							className="uk-height-large uk-overflow-auto"
							data-uk-height-viewport="offset-top: true; offset-bottom: 20px"
						>
							<div className="uk-flex uk-height-1-1">
								<div className="uk-width-1-2 uk-padding">
									<div className={ styles.row }>
										<div className={ styles.title }>{ loc.destinationLocation } *</div>
										<div className={ styles.content }>
											<InputText
												required={ true }
												icon={ faSearch }
												value={ order.get('wh_code_to') }
												disabled={ isLoading }
												onFocus={ this.onToggleModalWarehouseHandler }
												readOnly
											/>
											<div className="uk-text-muted uk-margin-small-top">
												{ order.get('wh_desc_to') }
											</div>
										</div>
									</div>
									<div className={ styles.row }>
										<div className={ styles.title }>{ loc.address }</div>
										<div className={ styles.content }>
											{ order.get('wh_to') ? order.get('wh_to').wh_add : <span>&nbsp;</span> }
										</div>
									</div>
									<div className={ styles.row }>
										<div className={ styles.title }>{ loc.phone }</div>
										<div className={ styles.content }>
											{ order.get('wh_to') ? order.get('wh_to').wh_tel : <span>&nbsp;</span> }
										</div>
									</div>
									<div className={ styles.row }>
										<div className={ styles.title }>{ loc.salesman } *</div>
										<div className={ styles.content }>
											<InputText
												required={ true }
												icon={ faSearch }
												value={ order.get('salesman_code') }
												disabled={ isLoading }
												onFocus={ this.onToggleModalSalesmanHandler }
												readOnly
											/>
											<div className="uk-text-muted uk-margin-small-top">
												{ order.get('salesman_name') }
											</div>
										</div>
									</div>
									<div className={ classnames(styles.row, styles.rowRemark) }>
										<div className={ styles.title }>{ loc.remark } *</div>
										<div className={ styles.content }>
											<textarea
												rows="4"
												cols="40"
												className={ classnames('uk-textarea', styles.remarkTextArea) }
												placeholder={ loc.remark }
												disabled={ isLoading }
												value={ order.get('remark') || '' }
												readOnly
												onFocus={ this.onFocusRemarkHandler }
											/>
										</div>
									</div>

									<div className="uk-margin-medium uk-text-small uk-text-muted uk-text-bold">
										{ loc.MSGFieldsRequired }
									</div>
								</div>
								<div className="uk-width-1-2 uk-padding uk-padding-remove-vertical">
									<div>
										<form onSubmit={ this.onSearchHandler }>
											<div className="uk-flex">
												<div className="uk-width-4-7">
													<InputText
														icon={ faSearch }
														value={ keyword }
														myRef={ this.keywordRef }
														autoFocus
														disabled={ isLoading || itemInfo.isFetching }
														onChange={ this.onChangeKeywordHandler }
														onSubmit={ this.onSearchHandler }
													/>
												</div>
												<div className="uk-width-3-7">
													<button
														className="uk-button uk-width-1-1 uk-padding-remove-vertical uk-position-relative uk-button-secondary uk-margin-left"
														type="button"
														onClick={ this.onToggleModalSelectBrandHandler }
														disabled={ isLoading }
													>
														{ loc.addItemsByBrand }
													</button>
												</div>
											</div>
										</form>
									</div>
									<ul className={ classnames('uk-list', 'uk-list-divider', 'uk-margin-top', styles.list) }>
										{
											order.get('transaction_items').map((transactionItem, index) => (
												<TransactionItemRow
													key={ `page-stock-transfer-out-order-transaction-item-row-${index}` }
													index={ index }
													transactionItem={ transactionItem }
													exceptions={
														exceptionMap[transactionItem.get('item_code')] ? exceptionMap[transactionItem.get('item_code')] : (
															exceptionMap[transactionItem.get('lot_no')] ? exceptionMap[transactionItem.get('lot_no')] : null
														)
													}
													forceSelectLotNo={ transactionItem.get('item_code') === forceSelectLotNo }
													onShowOverlay={ this.onShowOverlayHandler }
													onHideOverlay={ this.onHideOverlayHandler }
													onSelectLotNo={ this.onSelectLotNoHandler }
													onChange={ this.onChangeTransactionItemHandler }
													onRemove={ this.onRemoveTransactionItemHandler }
												/>
											))
										}
									</ul>
									<div className="uk-text-primary uk-text-small uk-padding-small uk-padding-remove-horizontal">
										{ loc.transferringItem }: { this.getTotalTransactionItemCount() }
									</div>
								</div>
							</div>
							<div className="uk-position-bottom-right">
								<Button
									text={ loc.checkOut }
									arrow={ true }
									theme="primary"
									disabled={ isLoading }
									onClick={ this.onClickCheckOutHandler }
								/>
							</div>
						</div>
					</div>
				</LoadingOverlay>

				<Overlay
					isOpen={ showOverlay }
					onToggle={ this.onHideOverlayHandler }
				/>

				<ModalWarehouse
					isOpen={ showModalWarehouse }
					onToggle={ this.onToggleModalWarehouseHandler }
					onSelect={ this.onSelectWarehouseHandler }
					filter="stock_transfer"
					title={ loc.destinationLocation }
				/>

				<ModalSalesman
					isOpen={ showModalSalesman }
					onToggle={ this.onToggleModalSalesmanHandler }
					onSelect={ this.onSelectSalesmanHandler }
					title={ loc.salesmanSearch }
					salesmanType={ showModalSalesman ? 'salesman' : '' }
				/>

				<ModalSelectItem
					isOpen={ showModalSelectItem }
					onToggle={ this.onToggleModalSelectItemHandler }
					keyword={ keyword }
					type="stockTransfer"
					onSelect={ this.onAddTransactionItemHandler }
				/>

				<ModalRemark
					isOpen={ showModalRemark }
					onToggle={ this.onToggleModalRemarkHandler }
					onChange={ this.onChangeRemarkHandler }
					docType="TO1"
					remark={ order.get('remark') }
					isLoading={ isLoading }
				/>

				<ModalSelectBrand
					isOpen={ showModalSelectBrand }
					onToggle={ this.onToggleModalSelectBrandHandler }
					keyword={ keyword }
					onSelect={ this.onAddItemsByBrandHandler }
				/>

				<ModalException
					isOpen={ showModalException }
					onToggle={ this.onToggleModalExceptionHandler }
				/>
			</Fragment>
		);
	}
}

export default withRouter(connect(
	(state) => ({
		itemInfo: state.itemInfo,
		stockInfo: state.stockInfo,
		transferOutOrderCreateInfo: state.transferOutOrderCreateInfo,
	}),
	(dispatch) => ({
		getItem: para => dispatch(getItem(para)),
		getStock: para => dispatch(getStock(para)),
		createTransferOutOrder: para => dispatch(createTransferOutOrder(para)),
	})
)(PageStockTransferOutOrder));