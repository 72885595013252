import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './AppliedFilterTag.less';

class AppliedFilterTag extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler(e) {
		e.preventDefault();
		const { onRemove, type, filterIndex, filterOptionIndex } = this.props;
		onRemove(type, filterIndex, filterOptionIndex);
	}

	render() {
		const { text } = this.props;
		return (
			<div className={ styles.container }>
				<div className={ styles.text }>
					{ text }
				</div>
				<div className={ styles.button } onClick={ this.onClickHandler }>
					<FontAwesomeIcon icon={ faTimes } />
				</div>
			</div>
		);
	}
}

AppliedFilterTag.propTypes = {
	text: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	filterIndex: PropTypes.number.isRequired,
	filterOptionIndex: PropTypes.number.isRequired,
	onRemove: PropTypes.func.isRequired,
};

export default AppliedFilterTag;