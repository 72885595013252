exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2DOuv {\n  padding-left: 20px;\n  padding-right: 20px;\n  line-height: 26px;\n  font-size: 15px;\n  color: #A0A0A0;\n  background-color: #EFF1F2;\n}\n._10G7h {\n  height: calc(100% - 50px);\n  overflow: auto;\n}\n._10G7h ._1RRKs {\n  height: calc(100% - 70px);\n  overflow: auto;\n}\n._10G7h ._1RRKs .A2Zs5 {\n  padding-left: 20px;\n  padding-right: 20px;\n  height: 50px;\n  border-bottom: 0.5px solid #CBCBCB;\n}\n._10G7h ._1RRKs .A2Zs5 ._1OkYw {\n  line-height: 50px;\n}\n._10G7h ._1RRKs .A2Zs5 .SneHb {\n  padding-top: 7px;\n  padding-bottom: 7px;\n}\n._10G7h ._1RRKs .A2Zs5.QRyOn {\n  min-height: 90px;\n  height: auto;\n}\n._10G7h ._1RRKs .A2Zs5.QRyOn ._1OkYw {\n  line-height: 53px;\n}\n._10G7h ._1RRKs .A2Zs5.QRyOn .SneHb {\n  padding-top: 10px;\n}\n._10G7h ._1RRKs .A2Zs5.QRyOn .SneHb ._2P-vr {\n  margin-bottom: 10px;\n}\n._10G7h ._1RRKs ._1XhY0 {\n  padding: 10px 20px;\n  border-bottom: 0.5px solid #CBCBCB;\n}\n._10G7h ._1RRKs ._1XhY0 .VZD0a {\n  padding-left: 10px;\n}\n._10G7h ._1RRKs ._13P0d {\n  padding: 5px 15px;\n}\n._10G7h .y8TQM {\n  padding: 15px 20px;\n  text-align: center;\n}\n.Wg1XH {\n  text-transform: capitalize;\n}\n", ""]);

// Exports
exports.locals = {
	"title": "_2DOuv",
	"form": "_10G7h",
	"filters": "_1RRKs",
	"row": "A2Zs5",
	"label": "_1OkYw",
	"value": "SneHb",
	"row2x": "QRyOn",
	"tab": "_2P-vr",
	"options": "_1XhY0",
	"buttonAdd": "VZD0a",
	"applied": "_13P0d",
	"button": "y8TQM",
	"tabButton": "Wg1XH"
};