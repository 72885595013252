import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageStockTransfer from 'Pages/stock-transfer';
import PageStockTransferOrder from 'Pages/stock-transfer-order';
import PageStockTransferOutOrder from 'Pages/stock-transfer-out-order';

const StockTransfer = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}/:page`} component={ PageStockTransfer } />
			<Route exact path={`${match.url}/out/create`} component={ PageStockTransferOutOrder } />
			<Route exact path={`${match.url}/:type/:orderType/:transactionNumber`} component={ PageStockTransferOrder } />
			<Redirect exact from={ match.url } to={`${match.url}/in`} />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default StockTransfer;