import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import loc from 'Components/languages';
import styles from './StockListItem.less';

class StockListItem extends PureComponent {
	render() {
		const { item, appLanguage } = this.props;
		return (
			<li className={ styles.list }>
				<div className="uk-card uk-card-body uk-card-small uk-position-relative">
					<div className="uk-flex uk-flex-between uk-h5 uk-text-uppercase uk-margin-remove-bottom">
						<div className="uk-text-primary">
							{ appLanguage === 'en' ? item.item_name_en : item.item_name_tc }
						</div>
					</div>
					<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize">
						<div className="uk-width-1-3">
							{ loc.itemCode }: { item.item_code }
						</div>
						<div className="uk-width-1-3">
							{ loc.brandName }: { appLanguage === 'en' ? item.brand.name_en : item.brand.name_tc }
						</div>
						<div className="uk-width-1-3">
							{ loc.qty }: { item.item_quantity }
						</div>
					</div>
					<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize">
						<div className="uk-width-1-3">
							{ loc.lotNo }: { item.lot_no }
						</div>
						<div className="uk-width-1-3">
							{ loc.category }: { appLanguage === 'en' ? item.category.name_en : item.category.name_tc }
						</div>
						<div className="uk-width-1-3">
						</div>
					</div>
				</div>
			</li>
		);
	}
}

StockListItem.propTypes = {
	index: PropTypes.number.isRequired,
	item: PropTypes.object.isRequired,
};

export default withRouter(connect(
	(state) => ({
		appLanguage: state.appLanguage,
	})
)(StockListItem));