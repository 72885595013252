exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2WWMx {\n  margin-bottom: 6px;\n}\n._2WWMx.nSSfs {\n  margin-top: 40px;\n}\n._2WWMx ._1J5_j {\n  margin-bottom: 5px;\n  font-weight: bold;\n}\n._2WWMx ._22Stt {\n  line-height: 35px;\n}\n._2WWMx ._22Stt ._1TjtS {\n  border: 1px solid #e5e5e5;\n}\n._2WWMx ._22Stt ._1TjtS:focus {\n  border: 1px solid #88733c;\n}\n._3519R {\n  height: calc(100% - 172px);\n  overflow: auto;\n}\n", ""]);

// Exports
exports.locals = {
	"row": "_2WWMx",
	"rowRemark": "nSSfs",
	"title": "_1J5_j",
	"content": "_22Stt",
	"remarkTextArea": "_1TjtS",
	"list": "_3519R"
};